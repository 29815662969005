<template>
  <div>
    <div class="overmask" :style="returnaCor('alphaLogin', false, true)"></div>
    <div class="container" id="Login">
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <b-row class="mt-5 text-center" v-if="erroConexao != ''">
        <b-col>
          <img :src="logo" style="max-width: 90%" />
          <div class="alert alert-warning">{{ erroConexao }}</div>
          <b-button @click="buscaDadosEmpresa()">Tentar Novamente</b-button>
        </b-col>
      </b-row>
      <div v-else class="mt-5">
        <div style="padding: 15% 0"></div>
        <div>
          <b-col class="text-center">
            <div class="text-white" style="margin-top: ">
              <h4 class="text-uppercase text-shadow">Confirmação de Dados</h4>
              <div class="alert alert-warning my-3" v-if="form.dadosRes">
                <span v-if="form.dadosRes.email">
                  Para continuar é importante que seu email seja confirmado
                </span>
                <span v-else>
                  Para continuar é importante que seu celular seja confirmado
                </span>
              </div>
            </div>
            <b-form-group
              v-if="form.dadosRes"
              id="input-group-2"
              label-for="input-2"
            >
              <b-form-input
                v-if="form.dadosRes.email"
                id="Email"
                name="Email"
                placeholder="Email"
                v-model="form.email"
                v-validate="{ required: true, email: true }"
                :state="validateState('Email')"
              ></b-form-input>
              <the-mask
                v-else
                mask="(##) #####-####"
                style="text-transform: uppercase"
                id="input-2"
                class="form-control"
                v-model="form.celular"
                placeholder="Celular"
                name="Celular"
                :masked="true"
                v-validate="{ required: true }"
                :state="validateState('Celular')"
                :class="validateClass('Celular')"
              />
            </b-form-group>
            <b-button
              type="submit"
              class="btn-block mt-3"
              variant="dark"
              :style="returnaCor('botaoLogin', true, false)"
              @click="validarDados()"
              >Continuar</b-button
            >
          </b-col>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import locale from '../locale';
import service from '../services';
import jwt from 'jsonwebtoken';

import colors from './../services/colors';
export default {
  name: 'DashBoard',
  props: {
    msg: String,
  },
  data() {
    return {
      form: {
        codigoVerificacao: null,
      },
      score: 0,
      erroConexao: '',
      carregando: false,
      logo: 'https://bboneapp.s3.amazonaws.com/evo-gerencial-color.png',
      termo: '<p></p>',
      exibeTermo: false,
      value: 80,
      max: 100,
      nomeEmpresa: null,
      emailEmpresa: null,
      temaCor: {
        botaoLogin: '#000000',
        alphaLogin: '#000000',
        botaoFormularios: '#000000',
      },
    };
  },
  components: {},
  methods: {
    escondeEmail(email) {
      var maskedEmail = email.replace(/([^@\.])/g, '*').split('');
      var previous = '';
      for (let i = 0; i < maskedEmail.length; i++) {
        if (i <= 1 || previous == '.' || previous == '@') {
          maskedEmail[i] = email[i];
        }
        previous = email[i];
      }
      return maskedEmail.join('');
    },
    escondeCelular(celular) {
      var arddd = celular.split(')');
      var ddd = arddd[0] + ')';
      var arrFim = celular.split('-');
      var fimCelular = arrFim[1];
      return ddd + '9****-' + fimCelular;
    },

    returnaCor(chave, comSombra, backgroundTransparent) {
      var rgb = {};
      if (this.temaCor[chave]) rgb = colors.hexToRgb(this.temaCor[chave]);

      if (backgroundTransparent)
        return `background-color: rgba(${rgb.r}, ${rgb.g}, ${rgb.b},0.3);`;
      if (comSombra)
        return `
      box-shadow: 0 5px 15px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9);
      background-color: ${this.temaCor[chave]};
      `;
      else
        return `
      background-color: ${this.temaCor[chave]};
      `;
    },
    showScore(score) {
      this.score = score;
    },
    selecionaTab(valor) {
      this.form.formSelecionado = valor;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    validarDados(evt) {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }

        var valida = false;
        if (this.form.dadosRes.email) {
          if (this.form.dadosRes.email == this.form.email) {
            valida = true;
          }
        } else {
          if (this.form.dadosRes.celular == this.form.celular) {
            valida = true;
          }
        }

        if (valida == false) {
          this.$bvToast.toast(
            'Os dados informados não conferem com os dados cadastrados previamente.',
            {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            },
          );
          return;
        }

        this.carregando = true;
        service
          .post('Login', 'confirmarDadosAssociado', this.form)
          .then((res) => {
            this.carregando = false;
            if (res.data.dadosRes) {
              localStorage.setItem(
                'dadosRes',
                JSON.stringify(res.data.dadosRes),
              );
            }

            console.log(res.data);
            this.$router.push({
              path: res.data.redirect,
            });
          })
          .catch((e) => {
            this.carregando = false;
            this.$bvToast.toast(e, {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          });
      });
    },
    buscaDadosEmpresa() {
      return new Promise((resolve, reject) => {
        this.carregando = true;
        this.logo = localStorage.getItem('logo');
        const token = localStorage.getItem('user-token');

        var dadosRes = localStorage.getItem('dadosRes');
        if (dadosRes) dadosRes = JSON.parse(dadosRes);
        this.form.idEmpresa = dadosRes.id_empresa;
        this.form.dadosRes = dadosRes;

        var temaCor = localStorage.getItem('temaCor');
        var tema = JSON.parse(temaCor);
        this.temaCor = tema;

        this.carregando = false;
        console.log(this.form.idEmpresa);
      });
    },
  },
  mounted() {
    this.$validator.localize('en', locale);
    this.buscaDadosEmpresa();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.carregando {
  color: #fff;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  padding-top: 50%;
}

.logoRodape {
  text-align: center;
  margin-top: 15px;
  font-size: 10px;
  display: block;
}
.link-senha {
  font-size: 13px;
  text-decoration: underline;
}

body {
  background-image: url('./../assets/images/1.png');
}
</style>
